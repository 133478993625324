body, html {
  font-size: .875rem;

}
main{
  position: relative !important;
  height: calc(100vh - 48px);
  width: calc(100vw - 250px);
  left: 250px;
  overflow: auto;
/* 
  transition: all 0.2s ease-in-out; */
}
.form-control:focus, .btn-primary{
  border-color: #0a5cbe !important;
  box-shadow: none !important;
}
.optionContainer li:hover, .highlight{
  background-color: #0a5cbe !important;
}
.menu_collapsed{
  
  width: 100vw !important;
  left: 0 !important;
}
.header{
  position: relative;
  height: 48px;
  width: calc(100vw - 250px);
  left: 250px;
  /* transition: all 0.2s ease-in-out; */
}
.header .header_brand{
  width: 280px;
  height: 48px;
  background-color: white;
  
}
nav .nav_actions{
  width: 100%;
  height: 48px;
}
.min-h{
  min-height: 640px
}
main::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  
}

select:-iternal-list-box:focus,option:checked{
  background-color: red;
  background: red;
}
.h-xs{
  height: 15px;
}
.list-data{
   
    border: 1px solid #CCC;
    max-height: 350px;
    
    overflow: auto;
}
.list-item{
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;  
}
.selected_users{
  
    overflow: auto;
    height: calc(100% - 135px);
}
option{

  width: 95%;
  margin: 5px 0px;
}
.nav-link{
  color: #0a5cbe;
}
.btn--link{
  color: #0a5cbe;
  padding-left: 0px;
  font-weight: bold;
  font-size: 14px;
}
.btn--link:hover{
  text-decoration: underline;
  color: #0a5cbe;
}
.text-red{
  color: red;
}

.text-link{
  cursor:pointer;
  transition:all .3s ease;
}

.h7{
  font-size: .9rem;
  margin-bottom: .35rem;
  display:none;
}

.text-link:hover{
  text-decoration: underline;
}

.spinner-border--small{
  width: 1rem !important;
  height: 1rem !important;
}
.btn-primary, .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: #0a5cbe !important;
  border-color: #0a5cbe;
}
.btn-primary:hover{
  opacity: 0.9 !important;
}
.section_left{
  border-left: 2px solid #0a5cbe;
}
.section_right{
  border-right: 2px solid #0a5cbe;
}
main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 10px;
}
 

main::-webkit-scrollbar-thumb {
  background: #0a5cbe; 
  border-radius: 10px;
}

.sidebar-heading{
  font-size: 14px !important;
  font-weight: bold;
  padding-left: 10px !important;
  padding-top: 15px;
  padding-bottom: 5px;
}
li a{
  font-size: 14px;
  padding-right: 0px !important;
  padding-left: 10px !important;
}
.bg-lightblue{
background-color: #F3F7FA;
}
.bg-blue{
  background-color: #0a5cbe !important;
}
.bg-lightgray{
  background-color:#ededed;
}

.bg-lightgray:hover{
  background-color: #ededed;
}

.blue-bg-text{
  background-color: #0a5cbe !important;
  color:#fff !important;
  transition: all .3s ease;
}
.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}
.txt-white{
  color: white;
}
th{
  text-transform: uppercase!important;
  font-size: 15px !important;
}
td{
  font-size: 14px !important;
}
/*
 * Sidebar
 */
 
 select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
 }

.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  background-color: white;
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
}

@media (max-width: 767.98px) {
  
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: white;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */
#sidebarMenu{
  box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
}
.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.label {
  display: flex;

  /* Rounded border */
  border-radius: 9999px;

  /* Size */
  height: 32px;
  width: 64px;

  /* OFF status */
  background-color: rgba(0, 0, 0, .1);
  border: 1px solid rgba(0, 0, 0, .3);

  /* ON status */
  background-color: #357edd;
  border: 1px solid #357edd;
  /* Push the circle to the right */
  justify-content: flex-end;
}

.label__input {
  /* Hide the input */
  display: none;
}

.label__circle {
  /* Rounded border */
  border-radius: 9999px;

  /* Size */
  width: 32px;

  background-color: #FFF;

  /* OFF status */
  border: 1px solid rgba(0, 0, 0, .3);
}
.MuiButton-label{
  color: #FFF !important;
}
.catalogo_form_modal, .sebas{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  
  min-height: calc(100vh - 48px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* overflow: auto; */
}
.catalogo_form_modal form{
  display: flex;
  justify-content: center;

}

h2{
  padding-top: 10px;
  color: #555;
}
h1,h2,h3,h4, h5{
  text-transform: uppercase;
  font-weight: 600;
  color: #555;
}
.line-height-xs{
  line-height: 0.3;
}
.form-check{
  margin: 5px;
}
.fecha_llegada{
  width: 250px !important;
}
.cta--rounded{
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.top{
  top: 0;
}
.right{
  right: 0;
}
.loader{
  position: fixed;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
}
.cursor-pointer{
  cursor: pointer !important;
}
.nav-item:hover{
  background-color: rgba(0, 0, 0, .1);
}
.top-bordered{
  border-top: 1px solid
  rgba(224, 224, 224, 1);
}
main{
  padding: 0px !important;
}

.loginview_sigin {
  width: 390px;
  background-color: #FFF;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0px 15px;
}

.cta {
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
  font-weight: bold;
  /* box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, .2); */
}
.wrap_cta{
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta--primary{
  background-color: rgba(10, 92, 190, 1);
  color: white;
}
.cta--primary:hover{
  background-color: rgba(10, 92, 190, 0.9);
}
.cta--big{
  width: 200px;
  height: 40px;
}
.cta--medium{
  width: 120px;
  height: 40px;
}
.cta--small{
  width: 60px;
  height: 40px;
}

.btn span{

  color: white;
  font-weight: bold;
  font-size: 16px;
}

.disabled{
  background-color: #e9ecef;
}
.absolute-center{
  position: absolute;
  top: 45vh;
  left: 50%;
  transform: translate(-50%, -50%);
}
.form-control-n{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.d-contents{
  display: contents;
}
.min-display{
  min-height: 300px;
}
.mh-100{
  min-height: 100%;
}
.cta--text{
  text-decoration: underline;
  cursor: pointer;
}
.cta--text:hover{
  color: #0a5cbe;
}
.w-0{
  width: 0px !important;
}
#sidebarMenu{
  /* transition: width 0.1s ease-in-out; */
}
.general_forms{

  white-space: nowrap;
  width: fit-content;
  min-width: 100%;
  padding-right: 320px !important;

}
.general_forms_r{

  white-space: nowrap;
  width: fit-content;
  min-width: 100%;
  

}
.form_frente_v{
  width: 400px !important ;
}
.form_general{
  width: 120px !important;

}
.form_frente{
  width: 360px;
}
.form_general, .form_frente{
  position: relative;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.2);
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  display: inline-table;
}
.form_frente_col{
  display: block !important;
  width: 100%;
}
.form_frente:nth-child(0){
  font-size: 30px !important;
}
.cta-title{
  margin: 12px 0px;
}
.sticky-top{
  position: sticky;
  top: 0px;
  z-index: 1;
}
.sticky-left{
  position: sticky;
  left: 0px;
  z-index: 1;
}
.border-radius-0{
  border-radius: 0px !important;
}
.cta--disabled{
  background-color: #888 !important;
  border: 1px solid #888 !important;
  color: #ccc !important;;
  cursor:not-allowed !important;
}
.cta--disabled:hover{
  opacity: 1 !important;
}
.form_items > div{
  width: 170px;
  padding: 5px;
}
.form-message{
  position: relative;
  width: 310px;
  margin-left: 15px;
  white-space: normal;
  color: red;
  padding-bottom: 10px;
} 
.multi-select{

}
.op{
  margin-left: 100px;
}

/* Estilos para la tabla de usuarios */

.proyect-table{
  width:100%;
  overflow-x: auto;
  height: auto;
}

.proyect-table th{
  background-color: #0a5cbe;
  color:#fff;
  white-space: nowrap;
  padding:20px 15px;
  position: sticky;
  top: 0;
}

.proyect-table tr{
  border-bottom:1px solid #f5f5f5;
}

.proyect-table td{
  padding:1.5rem;
}

.proyect-table .form-control{
  width:100%;
}

.proyect-table input{
  min-width: 5rem;
}

.large-input{
  min-width: 8rem !important;
}

.btn-add-item{
  background-color: #0a5cbe;
  color:#fff;
  border-radius:10rem;
  border:1px solid #dcdcdc;
}

.general-form-container{
  min-height:40rem;
}

.remove-link-styles{
  text-decoration: none;
  background: inherit;
  font-size: unset;
  color: inherit;
}

.remove-link-styles:hover{
  color: inherit;
  text-decoration: none;
}

.proyect-table select{
  min-width: 8rem;
}

.flex-1{
  flex:1;
}

.proyect-table .form-control:disabled{
  color: transparent;
  user-select: none;
  background: #e9ecef;
}